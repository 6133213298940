<template>
    <div class="background">
        <div class="contain">
            <div class="header">
                <div class="topbar">
                    <div style="flex-shrink: 1">
                        <img class="icon-stack" src="/images/back.png" width="30px" @click="$router.back()" />
                    </div>
                </div>
            </div>
            <div class="content">
                <h3 class="mb-0">Your Rewards</h3>
                <hr style="border: 1px solid white" />
                <div v-if="rewards.length == 0">
                    <h4 class="mb-0">No rewards :(</h4>
                    Better luck next time!
                </div>
                <div v-else>
                    <div v-for="reward in rewards" v-bind:key="reward.name" class="d-flex flex-row mt-3">
                        <div :class="'reward-bg ' + reward.tierCss">
                            <img height="60px" width="60px" :src="reward.image" />
                        </div>
                        <div class="d-flex flex-column justify-content-center ml-3 text-left">
                            <div class="font-weight-bold">{{ reward.quantity }}x {{ reward.name }}</div>
                            <img v-if="reward.tier == 1" src="/images/gold.png" class="gold-sizing" />
                            <img v-else-if="reward.tier == 2" src="/images/silver.png" class="silver-sizing" />
                            <img v-else-if="reward.tier == 3" src="/images/bronze.png" class="bronze-sizing" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'gacha-result',
    props: {
        rewards: { type: Array }
    },
    data() {
        return {

        }
    },
    methods: {

        playSilverAnimation() {

            this.popConfetti(60, 55, 0, 1)

            setTimeout(() => this.popConfetti(120, 55, 1, 1), 750)
            setTimeout(() => this.popConfetti(90, 20, 0.5, 1), 1500)

        },

        playGoldAnimation() {

            this.popConfetti(90, 10, 0, 1, 15)
            this.popConfetti(90, 10, 1, 1, 15)

            setTimeout(() => {
                this.popConfetti(90, 10, 0.2, 1, 15)
                this.popConfetti(90, 10, 0.8, 1, 15)
            }, 250)

            setTimeout(() => {
                this.popConfetti(90, 10, 0.4, 1, 15)
                this.popConfetti(90, 10, 0.6, 1, 15)
            }, 500)

            setTimeout(() => this.popConfetti(90, 10, 0.5, 1, 15), 750)
            
            setTimeout(() => {
                this.popConfetti(90, 10, 0.4, 1, 15)
                this.popConfetti(90, 10, 0.6, 1, 15)
            }, 1000)
            
            setTimeout(() => {
                this.popConfetti(90, 10, 0.2, 1, 15)
                this.popConfetti(90, 10, 0.8, 1, 15)
            }, 1250)

        },

        popConfetti(angle, spread, originX, originY, pc = 50) {
            this.$confetti.default({ particleCount: pc, angle: angle, spread: spread, origin: { x: originX, y: originY }})
        }

    },
    created() {

        if(this.rewards == null || this.rewards == undefined) {
            return this.$router.replace("/")
        }

        let highestTier = Math.min(...this.rewards.map(it => it.tier))

        if(this.rewards.length != 0) {
            if(highestTier == 2) {
                this.playSilverAnimation()
            }
            else if(highestTier == 1) {
                this.playGoldAnimation()
            }
        }

    }
}
</script>
